import React from 'react'
import Link from 'next/link'
import cn from 'classnames'

import { abbreviateNumber, addComma, formatAvgRating } from '@lib/utils'
import { BookEnum } from '@interfaces/BookEnum'
import { TagType } from '@models/story/TagType'
import { EBookIcon } from '@components/icons/EBookIcon'
import { PenIcon } from '@components/icons/PenIcon'
import { HeartEbookSolidIcon } from '@components/icons/HeartEbookSolidIcon'
import { HeartEbookIcon } from '@components/icons/HeartEbookIcon'
import { EbookImage } from '@components/EbookImage/EbookImage'
import { StarRating } from '@components/StarRating'
import { EbookCardProps } from './interface'
import { MobileTabletFooter } from './components/MobileTabletFooter'
import { PromotionTime } from './components/PromotionTime'

export function EbookCard({
  id,
  title,
  imageUrl,
  categoryId,
  penName,
  content,
  category,
  isWishlist,
  wishlistCount,
  review,
  tags,
  direction,
  bookType,
  status,
  slug,
  categoryPath,
  avgRating,
  price,
  promotion,
  precision,
  totalStars,
  isShowStatus,
  isToggleWishlist,
  isPurchased,
  isReplace,
  isRead,
  showPrice,
  handleWishlist,
  handleGoToSearchTagPage,
  showCategoryDialog,
  handleClickEbookCard,
  createUserTagClick,
}: EbookCardProps) {
  return (
    <div
      className={cn(
        'h-fit relative p-[14px] w-full flex rounded-[8px] border border-gray-4 dark:border-purple shadow-card tablet-mobile:p-[10px] tablet-mobile:grid cursor-pointer',
        {
          'flex-col w-full': direction === 'vertical',
        }
      )}
      onClick={handleClickEbookCard}
    >
      <Link href={`/ebook/${id}`} prefetch={false} legacyBehavior>
        <a
          className={cn('shrink-0', {
            'mb-[10px] tablet-mobile:mb-[8px]': direction === 'vertical',
            'desktop:max-w-[140px] w-full tablet-mobile:mb-[8px] desktop:mr-[20px]':
              direction === 'horizontal',
          })}
        >
          <EbookImage
            className='w-full'
            imageUrl={imageUrl}
            alt={title}
            isRead={isRead}
          />
        </a>
      </Link>

      {promotion && promotion.isPublish && (
        <PromotionTime
          discountPercent={promotion.discountPercent}
          endPublishedAt={promotion.endPublishedAt}
        />
      )}

      <section className='flex-1'>
        <Link href={`/ebook/${id}`} prefetch={false} legacyBehavior>
          <a
            className={cn(
              'font-mitr text-[18px] tablet-mobile:text-[14px] text-secondary dark:text-dark-10 font-medium line-clamp-1 break-word',
              {
                'mb-[6px] tablet-mobile:mb-[4px]': direction === 'horizontal',
                'mb-[10px] tablet-mobile:mb-[4px]': direction === 'vertical',
              }
            )}
          >
            {title}
          </a>
        </Link>
        <section
          className={cn(
            'flex justify-between font-light text-[14px] tablet-mobile:text-[12px] text-secondary',
            {
              'flex-col tablet-mobile:flex-row h-[42px] mb-[4px]':
                direction === 'vertical',
              'mb-[15px] tablet-mobile:mb-[10px]': direction === 'horizontal',
            }
          )}
        >
          <div
            className='grid grid-cols-[18px_1fr] gap-x-[5px] items-center pr-[10px] mr-[10px]'
            onClick={e => e.stopPropagation()}
          >
            <PenIcon className='w-[18px] h-[18px] text-secondary-1' />
            <Link
              href={`/profile/${slug}?tab=penName&text=${penName}`}
              legacyBehavior
            >
              <a className='hover:underline break-all truncate dark:text-dark-10'>
                {penName}
              </a>
            </Link>
          </div>
        </section>
        <p
          className={cn(
            'font-light text-[14px] text-secondary dark:text-dark-10 tablet-mobile:hidden line-clamp-2 break-word leading-[1.75] h-[48px]',
            {
              'mb-[15px]': direction === 'horizontal',
              'mb-[8px]': direction === 'vertical',
            }
          )}
        >
          {content}
        </p>

        <section
          className={cn('flex items-center text-[12px] font-light', {
            'flex-wrap mb-[15px] tablet-mobile:mb-[10px] desktop-tablet:h-[50px]':
              direction === 'vertical',
            'mb-[10px] desktop:space-x-[10px]': direction === 'horizontal',
          })}
        >
          <div
            className={cn(
              'flex items-center tablet-mobile:items-start tablet-mobile:flex-col tablet-mobile:w-full',
              {
                'w-full mb-[10px] tablet-mobile:mb-0 tablet-mobile:flex-col':
                  direction === 'vertical',
                'desktop:h-[24px]': direction === 'horizontal',
              }
            )}
          >
            <div className='flex items-center text-secondary dark:text-dark-10 pr-[10px] mr-[10px] border-r border-gray-4 whitespace-nowrap tablet-mobile:border-0'>
              <EBookIcon className='mr-[5px]' width='18' height='18' />
              {bookType === BookEnum.MANGA ? 'การ์ตูนอีบุ๊ก' : 'นิยายอีบุ๊ก'}
            </div>
            <div className='flex items-center w-full'>
              <div
                onClick={event => {
                  event.stopPropagation()
                }}
              >
                <Link href={`/category-ebook?id=${categoryId}`}>
                  <a
                    className={cn(
                      'text-primary underline line-clamp-1 break-word',
                      {
                        'max-w-[80px]': isShowStatus && !!status,
                      }
                    )}
                  >
                    {category}
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className='h-[22px] flex space-x-[10px]'>
            {tags.slice(0, 2).map((tag: TagType, index: number) => (
              <div
                key={index}
                className='px-[10px] py-[2px] text-secondary-1 dark:text-white-2 bg-gray-4 dark:bg-dark-13 tablet-mobile:hidden rounded-[4px] max-w-[100px] truncate relative'
                onClick={event => {
                  event.stopPropagation()
                  createUserTagClick(tag.id)
                }}
              >
                <Link
                  href={handleGoToSearchTagPage(tag.name)}
                  replace={isReplace}
                >
                  <a>{tag.name}</a>
                </Link>
              </div>
            ))}
            {tags.length > 2 ? (
              <button
                className='px-[5px] py-[2px] border border-gray-4 dark:border-dark-10 rounded-[4px] tablet-mobile:hidden text-secondary-1 dark:text-dark-10 min-w-fit font-light'
                type='button'
                onClick={event => {
                  event.stopPropagation()
                  showCategoryDialog()
                }}
              >
                + {abbreviateNumber(tags.slice(2).length, 0)}
              </button>
            ) : null}
          </div>
        </section>

        <MobileTabletFooter
          id={id}
          wishlistCount={wishlistCount}
          direction={direction}
          avgRating={avgRating}
          price={price}
          showPrice={showPrice}
          promotion={promotion}
          isWishlist={isWishlist}
          isToggleWishlist={isToggleWishlist}
          isPurchased={isPurchased}
          handleWishlist={handleWishlist}
        />

        <section className='flex justify-between items-center text-[12px] font-light text-secondary dark:text-dark-10 space-x-[10px] tablet-mobile:hidden'>
          <div
            className={cn('flex space-x-[26px]', {
              'space-x-[20px]': direction === 'vertical',
            })}
          >
            <div
              className={cn('flex', {
                'flex-col': direction === 'vertical',
              })}
            >
              <StarRating
                starsWidth='18px'
                starHeight='18px'
                className={cn({
                  'mr-[4px]': direction === 'horizontal',
                })}
                precision={precision}
                totalStar={totalStars}
                activeStar={avgRating || 0}
                viewOnly
              />
              <div className='font-medium text-secondary dark:text-dark-10'>
                &nbsp;{formatAvgRating(avgRating || 0)}&nbsp;
                <span className='text-secondary-1 font-light'>
                  ({addComma(review || 0)})
                </span>
              </div>
            </div>
            {showPrice && (
              <div
                className={cn('flex items-center', {
                  'flex-col': direction === 'vertical',
                })}
              >
                <div className='shrink-0 w-[18px] h-[18px]'>
                  {/* <Image
                    src='/coin.webp'
                    width={18}
                    height={18}
                    alt='coin icon'
                  /> */}
                  <img
                    src='/coin.webp'
                    width={18}
                    height={18}
                    alt='coin icon'
                    className='w-[18px] h-[18px]'
                  />
                </div>

                {promotion && promotion.isPublish ? (
                  <div className='ml-[4px] text-secondary-1 line-clamp-1 break-all'>
                    <span className='text-yellow font-light font-bold'>
                      {addComma(promotion.discountPrice)}&nbsp;
                    </span>
                    <span className='line-through'>
                      ({addComma(promotion.price)})
                    </span>
                  </div>
                ) : (
                  <span className='ml-[4px] font-bold text-yellow line-clamp-1 break-all'>
                    {addComma(price!)}
                  </span>
                )}
              </div>
            )}
          </div>

          {!isPurchased ? (
            <button
              className={cn(
                'px-[10px] py-[4px] border border-gray-4 dark:border-purple rounded-[8px] flex items-center',
                {
                  'text-secondary dark:text-dark-10': isWishlist,
                  'text-secondary-1': !isWishlist,
                  'flex-col': direction === 'vertical',
                }
              )}
              type='button'
              disabled={isToggleWishlist}
              onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                if (handleWishlist) await handleWishlist(id)
              }}
            >
              <HeartEbookIcon
                className={cn('text-secondary dark:text-primary', {
                  'mr-0': direction === 'horizontal',
                  'flex-col': direction === 'vertical',
                  hidden: isWishlist,
                })}
                width='20'
                height='20'
              />
              <HeartEbookSolidIcon
                className={cn('text-red', {
                  'flex-col': direction === 'vertical',
                  hidden: !isWishlist,
                })}
                width='20'
                height='20'
              />
              {wishlistCount !== undefined ? (
                <span className='text-secondary-1 ml-[5px]'>
                  {abbreviateNumber(wishlistCount)}
                </span>
              ) : (
                ''
              )}
            </button>
          ) : null}
          {isPurchased ? (
            <div className='bg-blue-2 dark:bg-purple px-[10px] py-[6px] rounded-[4px] text-[12px] dark:text-white-2 font-right text-primary'>
              ซื้อแล้ว
            </div>
          ) : null}
        </section>
      </section>
    </div>
  )
}
